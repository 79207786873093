import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import styles from "../css/index.module.scss"
const Layout = ({ title, children }) => {
  return (
    <div className={styles.globalWrapper}>
      <SEO title={title} />
      <div className={styles.headerBar}>
        <a href="/">
          <div className={styles.logo}></div>
        </a>
        <div className={styles.menu}>
        <Link to="/competition/">竞赛</Link>/<Link to="/research/">科研</Link>/<Link to="/design/">毕设</Link>/
          <Link to="/about/">关于</Link>
          {/* <input></input> */}
        </div>
      </div>
      <main>{children}</main>
      <footer className={styles.footer}>
        <p>&copy; DONGXI INNO LAB</p>
      </footer>
    </div>
  )
}

export default Layout
